<template>
  <IndexView>
    <VHeader>
      <template #avatar>
        <VImage
          :name="data?.name"
          :color="data?.colour"
          :width="12"
          :height="12"
          class="items-center justify-center"
        />
      </template>

      <template #title>
        {{ data?.name }}
      </template>

      <template #subtitle>
        {{ data?.code }}
      </template>

      <template #status>
        <VChip
          :text="$t(`app.${data?.status}`)"
          :class="getStatusColor(data?.status)"
        />
      </template>

      <template #tabs>
        <div class="flex justify-between">
          <VTabs
            :current-tab="currentTab"
            :tabs="tabs"
            @click="onClickRedirect"
          />
        </div>
      </template>
    </VHeader>

    <router-view :id="id" :key="id" :resource="data" />
  </IndexView>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useTabs from "@/composables/useTabs";
import useReadOne from "@/composables/useReadOne";
import useColor from "@/composables/useColor";
// Components
import IndexView from "./IndexView";
import VHeader from "@/components/VHeader";
import VTabs from "@/components/VTabs";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    IndexView,
    VHeader,
    VTabs,
    VImage,
    VChip
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const route = useRoute();

    // Constants
    const baseRoute = "teaching-roles";
    const TABS = {
      OVERVIEW: "overview",
      LEARNERS: "learners",
      INSTRUCTORS: "instructors",
      TRAININGS: "trainings",
      COLLECTIONS: "collections"
    };

    // Composables
    const { buildTab } = useTabs();
    const { getStatusColor } = useColor();
    const { data, isLoading, read } = useReadOne();

    // Computed
    const currentTab = computed(() => {
      switch (route.name) {
        case `${baseRoute}-overview`:
          return "overview";
        case `${baseRoute}-learners`:
          return "learners";
        case `${baseRoute}-instructors`:
          return "instructors";
        case `${baseRoute}-trainings`:
          return "trainings";
        case `${baseRoute}-collections`:
          return "collections";
        default:
          return "";
      }
    });

    const tabs = computed(() => {
      return Object.values(TABS).map(tab => buildTab({ name: tab }));
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.roles.read",
        pathParams: { id: props.id }
      });
    };

    const onClickRedirect = tab => {
      router.push({
        name: `${baseRoute}-${tab}`,
        params: {
          id: props.id
        }
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      onClickRedirect,
      tabs,
      currentTab,
      // useReadOne
      data,
      isLoading,
      // useColor
      getStatusColor
    };
  }
};
</script>
